
import { mapGetters, mapActions, mapMutations } from "vuex"
import { FadeTransition } from "vue2-transitions"
import Search from "@/dashboard/search/sandwich-search"
import InsightsSidenavGlobal from "@/dashboard/sidebar/InsightsSidenavGlobal"
import ContentFooter from "@/helpers/layouts/ContentFooter"
import NavBar from "@/helpers/navigation/NavBar"
import DarkModeMixin from "@/mixins/dark-mode"
import Sidebar from "@/helpers/navigation/Sidebar"
import _ from "lodash"
import NavbarBananaPage from "@/dashboard/navigation/navbar-banana-page"
import NavbarBananaPageMobile from "@/dashboard/navigation/navbar-banana-page/NavbarBananaPageMobile"

export default {
  name: "AccountLayout",
  components: {
    InsightsSidenavGlobal,
    FadeTransition,
    ContentFooter,
    NavBar,
    Search,
    Sidebar,
    NavbarBananaPage,
    NavbarBananaPageMobile,
  },
  mixins: [DarkModeMixin],
  data() {
    return {
      items: [],
      shouldFilterByOnboarding: false,
    }
  },
  head() {
    return {
      title: "Insights Dashboard - StoragePug",
      meta: [],
    }
  },
  computed: {
    ...mapGetters({
      layoutVars: "layoutVars/getLayout",
      getFilterByOnboarding: "layoutVars/getFilterByOnboarding",
      user: "user/getUser",
      selectedAccount: "accounts/getSelectedAccount",
      isResetAccount: "layoutVars/getIsResetAccount",
    }),
    showGlobalSidenav() {
      if (this.selectedAccount && this.selectedAccount.id) {
        return false
      }

      return true
    },
  },
  mounted() {
    this.setSelectedAccount(null)
    this.resetSidebar()
    this.$nextTick(() => {
      this.shouldFilterByOnboarding = _.clone(this.getFilterByOnboarding)
    })

  },
  watch: {
    shouldFilterByOnboarding() {
      this.updateFilterByOnboarding(this.shouldFilterByOnboarding)
    },
  },
  methods: {
    ...mapActions({
      setSelectedAccount: "accounts/setSelectedAccount",
      resetSidebar: "navigation/resetSidebar",
    }),
    ...mapMutations({
      updateFilterByOnboarding: "layoutVars/updateFilterByOnboarding",
      updateSearchItems: "layoutVars/updateSearchItems",
    }),
    handleSelectedEnter(account) {
      this.$router.push(`/dashboard/${ account.id }`)
    },
    handleSelected(selected) {
      if (selected !== null) {
        this.updateSearchItems(selected)
      } else {
        this.updateSearchItems([])
      }
    },
    goBack() {
      this.$router.back()
    },
  },
}
