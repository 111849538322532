
import { mapGetters, mapMutations } from "vuex"
import { FadeTransition } from "vue2-transitions"

import Search from "@/dashboard/search/sandwich-search"
import Sidebar from "@/helpers/navigation/Sidebar"
import ContentFooter from "@/helpers/layouts/ContentFooter"
import NavBar from "@/helpers/navigation/NavBar"
import NavbarBananaPage from "@/dashboard/navigation/navbar-banana-page"
import { PubSub } from "aws-amplify"
import localforage from "localforage"
import DarkModeMixin from "@/mixins/dark-mode"
import InsightsSidenavGlobal from "@/dashboard/sidebar/InsightsSidenavGlobal"
import { format } from "date-fns"
import _ from "lodash"
const AOS = require("aos")
import NavbarBananaPageMobile from "@/dashboard/navigation/navbar-banana-page/NavbarBananaPageMobile"

export default {
  name: "DefaultLayout",
  components: {
    Sidebar,
    FadeTransition,
    ContentFooter,
    NavBar,
    Search,
    NavbarBananaPage,
    InsightsSidenavGlobal,
    NavbarBananaPageMobile,
  },
  mixins: [DarkModeMixin],
  data() {
    return {
      items: [],
      socket: null,
      socketStatus: {},
      subSuccess: null,
      subError: null,
      filteredLocations: null,
      refreshPage: false,
      format,
    }
  },
  computed: {
    ...mapGetters({
      layoutVars: "layoutVars/getLayout",
      isResetAccount: "layoutVars/getIsResetAccount",
      // isDarkMode: "layoutVars/getIsDarkMode",
      getUnitLocationFilters: "leads/filterLocations",
      locations: "locations/getLocations",
      user: "user/getUser",
      selectedAccount: "accounts/getSelectedAccount",
      isImageUploading: "media/getIsUploading",
      unitCacheStatus: "cache/getUnitCacheStatus",
      selectedAccountId: "user/getSelectedAccountId",
      uploadingError: "media/getIsUploadingError",
      uploadingErrorData: "media/getIsUploadingErrorData",
      rhsClientCodes: "accounts/getRhsClientCodes",
    }),
    updatedAt() {
      return format(new Date(this.layoutVars.edited.updated), "MM/dd/yyyy hh:mm a")
    },
  },
  async mounted() {
    // this.listenForWebsocket()

    // if (this.isDarkMode === true) {
    //   document.getElementById("__nuxt").classList.add("pug-dark-mode")
    // } else {
    //   document.getElementById("__nuxt").classList.add("bg-light")
    // }

    this.sendHotjarEvent({ action: 'Insights Visit' })
    if (this.selectedAccount) {
      let filter = await this.$vlf.getItem(`filterLocations_${ this.selectedAccount.id }`)
      let isRhs = _.findIndex(this.rhsClientCodes, (o) => { return o == this.selectedAccount.client_code })

      if (isRhs != -1) {
        this.locationFilters = null
        this.updateFilterLocations(null)
      }
      else if (filter && !this.getUnitLocationFilters) {
        this.filteredLocations = filter
        this.updateFilterLocations(this.filteredLocations)
      }
      else if (this.getUnitLocationFilters && this.getUnitLocationFilters.account_id == this.selectedAccount.id) {
        this.filteredLocations = this.getUnitLocationFilters
        this.updateFilterLocations(this.filteredLocations)
        await this.$vlf.setItem(`filterLocations_${ this.selectedAccount.id }`, this.getUnitLocationFilters)
      }
      else if (!this.getUnitLocationFilters && !filter) {
        this.filteredLocations = this.locations[0]
        await this.$vlf.setItem(`filterLocations_${ this.selectedAccount.id }`, this.locations[0])
      }
    }

    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "load", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 200, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 200, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    })
    AOS.refreshHard()
    let cacheExpires = await this.$vlf.getItem("cache_expires")
    if (cacheExpires === null || cacheExpires < Date.now()) {
      var ONE_HOUR = 60 * 60 * 1000
      var EXPIRE_TIME = ONE_HOUR * 24
      var expires = new Date(new Date().getTime() + EXPIRE_TIME)
      localforage.clear()
      setTimeout(async () => {
        await this.$vlf.setItem("cache_expires", expires)
      })
    }

  },
  destroyed() {
    // this.subSuccess.unsubscribe()
    // this.subError.unsubscribe()
  },
  watch: {
    // isDarkMode() {
    //   if (this.isDarkMode === true) {
    //     document.getElementById("__nuxt").classList.remove("bg-light")
    //     document.getElementById("__nuxt").classList.add("pug-dark-mode")
    //   } else if (this.isDarkMode === false) {
    //     document.getElementById("__nuxt").classList.add("bg-light")
    //     document.getElementById("__nuxt").classList.remove("pug-dark-mode")
    //   }
    // },
    resetAccountKey() {
      this.refreshPage = true
      setTimeout(() => {
        this.refreshPage = false
      }, 250)
    },
    uploadingError() {
      if (this.uploadingError === true) {
        let msg = `${ this.uploadingErrorData.length } images failed! `
        for (const item of this.uploadingErrorData) {
          msg += " " + item.file_name_original
        }
        this.toastError(msg, true)
      }
    },
    isImageUploading() {
      if (this.isImageUploading === true) {
        this.$nuxt.$loading.start()
      } else {
        this.$nuxt.$loading.finish()
      }
    },
  },
  methods: {
    ...mapMutations({
      updateUnitCacheStatus: "cache/updateUnitCacheStatus",
      updateSearchItems: "layoutVars/updateSearchItems",
      updateFilterLocations: "leads/updateFilterLocations",
    }),
    getCookie(name) {
      var value = "; " + document.cookie
      var parts = value.split("; " + name + "=")
      if (parts.length == 2) return parts.pop().split(";").shift()
    },
    goBack() {
      this.$router.back()
    },
    handleSelectedEnter(selectedSearchItem) {
      if (this.layoutVars.selectedEnterFunction && this.layoutVars.selectedEnterFunction instanceof Function) {
        try {
          this.layoutVars.selectedEnterFunction(selectedSearchItem)
        } catch (e) {
          console.log("🚀 ~ file: default.vue:216 ~ handleSelectedEnter ~ e:", e)
        }
      }
    },
    listenForImageUploadEvents() {
      PubSub.subscribe("image upload error").subscribe({
        next: (data) => {
          this.toastError(`Image upload error: ${ data }`, true)
        },
        error: (error) => console.error(error),
      })
    },
    listenForWebsocket() {
      this.subSuccess = PubSub.subscribe("update_cache_success").subscribe({
        next: (data) => {
          try {
            // if (this.unitCacheStatus[data.value.account_id] !== undefined) {
            // this.updateUnitCacheStatus({
            //   account_id: this.selectedAccountId,
            //   status: 'success'
            // })
            this.$bvToast.toast(data.value.message, {
              title: "Success",
              autoHideDelay: 8000,
              variant: "success",
              toaster: "b-toaster-bottom-right",
            })
            // }
          } catch (e) {
            // this.$rollbar.error(e)
          }
        },
        error: (error) => {
          this.$rollbar.error('CacheSuccess: Error with pubsub', error, { account_id: this.selectedAccountId })
        },
      })
      this.subError = PubSub.subscribe("update_cache_error").subscribe({
        next: (data) => {
          try {
            // if (this.unitCacheStatus[data.value.account_id] !== undefined) {
            this.updateUnitCacheStatus({
              account_id: this.selectedAccountId,
              status: "error",
            })
            this.toastError(data.value.message, true)
            // }
          } catch (e) {
            this.$rollbar.error('CacheError: Error updating cache status', e, { account_id: this.selectedAccountId })
          }
        },
        error: (error) => {
          this.$rollbar.error('CacheError: Error with pubsub', error, { account_id: this.selectedAccountId })
        },
      })
    },
  },
}
