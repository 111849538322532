import { render, staticRenderFns } from "./charts.vue?vue&type=template&id=043fa287&scoped=true&lang=pug"
import script from "./charts.vue?vue&type=script&lang=js"
export * from "./charts.vue?vue&type=script&lang=js"
import style0 from "./charts.vue?vue&type=style&index=0&id=043fa287&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "043fa287",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Sidebar: require('/helpers/navigation/Sidebar.vue').default,IdleLayover: require('/helpers/IdleLayover.vue').default,NavBar: require('/helpers/navigation/NavBar.vue').default,StatsDateFilter: require('/dashboard/stats/stats-date-filter/StatsDateFilter.vue').default,NavbarBananaPage: require('/dashboard/navigation/navbar-banana-page/NavbarBananaPage.vue').default,NavbarBananaPageMobile: require('/dashboard/navigation/navbar-banana-page/NavbarBananaPageMobile.vue').default,ContentFooter: require('/helpers/layouts/ContentFooter.vue').default})
