
import { mapGetters, mapActions, mapMutations } from "vuex"
import { FadeTransition } from "vue2-transitions"

import _ from "lodash"
import Search from "@/dashboard/search/sandwich-search"
import Sidebar from "@/helpers/navigation/Sidebar"
import ContentFooter from "@/helpers/layouts/ContentFooter"
import NavBar from "@/helpers/navigation/NavBar"
import NavbarBananaPage from "@/dashboard/navigation/navbar-banana-page"
import NavbarBananaPageMobile from "@/dashboard/navigation/navbar-banana-page/NavbarBananaPageMobile"
// const DateRangePicker = require('tiny-date-picker/date-range-picker')
import { PubSub } from "aws-amplify"
import DarkModeMixin from "@/mixins/dark-mode"

export default {
  name: "DefaultLayout",
  components: {
    Sidebar,
    FadeTransition,
    ContentFooter,
    NavBar,
    Search,
    NavbarBananaPage,
    NavbarBananaPageMobile
  },
  mixins: [DarkModeMixin],
  data() {
    return {
      items: [],
      subSuccess: null,
      subError: null,
      exportName: "",
      dash_id: null,
    }
  },
  computed: {
    ...mapGetters({
      layoutVars: "layoutVars/getLayout",
      user: "user/getUser",
      isImageUploading: "media/getIsUploading",
      unitCacheStatus: "cache/getUnitCacheStatus",
      selectedAccount: "accounts/getSelectedAccount",
      selectedAccountId: "user/getSelectedAccountId",
      uploadingError: "media/getIsUploadingError",
      isResetAccount: "layoutVars/getIsResetAccount",
      uploadingErrorData: "media/getIsUploadingErrorData",
    }),
    datePickerRef() {
      return `datepicker_${ Math.floor(Math.random() * 10000000) }`
    },
    isCalenderPage() {
      return this.$route.name === 'dashboard-account_id-calendar'
    },
  },
  beforeMount() {
    // this.listenForWebsocket()
    if (this.$route.params.dashboard_id) this.dash_id = this.$route.params.dashboard_id
  },
  mounted() {
    // this.listenForWebsocket()
    // console.log(this.$route)
  },
  destroyed() {
    // this.subSuccess.unsubscribe()
    // this.subError.unsubscribe()
  },
  watch: {
    uploadingError() {
      if (this.uploadingError === true) {
        let msg = `${ this.uploadingErrorData.length } images failed! `
        for (const item of this.uploadingErrorData) {
          msg += " " + item.file_name_original
        }
        this.toastError(msg, true)
      }
    },
    isImageUploading() {
      if (this.isImageUploading === true) {
        this.$nuxt.$loading.start()
      } else {
        this.$nuxt.$loading.finish()
      }
    },
  },
  methods: {
    ...mapMutations({
      updateUnitCacheStatus: "cache/updateUnitCacheStatus",
    }),
    showPrint() {
      this.exportName = `${ this.selectedAccount.name }-${ this.layoutVars.title }`
      this.$refs["modal"].show()
    },
    cancel() {
      this.$refs["modal"].hide()
    },
    async print() {
      const el = this.$refs.dashboard
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {}
      let canvas = await this.$html2canvas(document.getElementById("grid-stack"), options)
      var myImage = canvas.toDataURL("image/png")
      var link = document.createElement("a")
      link.href = myImage
      link.download = `${ this.exportName }.png`
      //Firefox requires the link to be in the body
      document.body.appendChild(link)
      //simulate click
      link.click()
      //remove the link when done
      document.body.removeChild(link)
      this.$refs["modal"].hide()
    },
    goBack() {
      this.$router.back()
    },
    listenForWebsocket() {
      this.subSuccess = PubSub.subscribe("update_cache_success").subscribe({
        next: (data) => {
          console.log("Message received charts", data)
          try {
            if (this.unitCacheStatus[data.value.account_id] !== undefined) {
              this.updateUnitCacheStatus({
                account_id: this.selectedAccountId,
                status: "success",
              })
              this.$bvToast.toast(data.value.message, {
                title: "Success",
                autoHideDelay: 8000,
                variant: "success",
                toaster: "b-toaster-bottom-right",
              })
            }
          } catch (e) {
            this.$rollbar.error('Charts CacheSuccess: Error updating unit cache status', e, { account_id: this.selectedAccountId })
          }
        },
        error: (error) => {
          this.$rollbar.error('Charts CacheSuccess: Error with pubsub', error, { account_id: this.selectedAccountId })
        },
        close: () => console.log("Done"),
      })
      this.subError = PubSub.subscribe("update_cache_error").subscribe({
        next: (data) => {
          console.log("Message received", data)
          try {
            if (this.unitCacheStatus[data.value.account_id] !== undefined) {
              this.updateUnitCacheStatus({
                account_id: this.selectedAccountId,
                status: "error",
              })
              this.toastError(data.value.message, true)
            }
          } catch (e) {
            this.$rollbar.error('Charts CacheError: Error updating unit cache status', e, { account_id: this.selectedAccountId })
          }
        },
        error: (error) => {
          this.$rollbar.error('Charts CacheError: Error with pubsub', error, { account_id: this.selectedAccountId })
        },
        close: () => console.log("Done"),
      })
    },
  },
}
