
export default {
  name: "ErrorLayout",
  props: {
    /**
     * Errors object, e.g. { statusCode: 404, message: "This page wasn't found." }
     *
     * @see {@link https://nuxtjs.org/guide/views#error-page} Nuxt Error layout
     * @see {@link https://medium.com/@mavrickmaster/custom-error-pages-with-nuxt-js-3c70e6c51aff} Custom error page layout
     */
    error: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    /**
     * Parses out the statusCode value in the error object
     */
    errorCode() {
      return this.error.statusCode || 404
    },
    /**
     * An error message related to the type of statusCode.
     * This is set up for the future if we want to handle for other error messages, right now it's just 404 as a catch-all.
     */
    errorMessage() {
      switch (this.errorCode) {
        case 404:
          return {
            code: this.errorCode,
            title: "This page wasn't found.",
            description: "Go back to our homepage or try to refresh this page.",
            image: "https://www.storagepug.com/hubfs/New-StoragePug-Website-Nov-2019/Error%20Notification/Tiny/admin-illustration-01.png",
          }
        default:
          return {
            code: this.errorCode,
            title: this.error.message,
            description: "Go back to our homepage or try to refresh this page.",
            image: "https://www.storagepug.com/hubfs/New-StoragePug-Website-Nov-2019/Error%20Notification/Tiny/admin-illustration-01.png",
          }
      }
    },
  },
  mounted() {
    // Track anytime a user reaches nuxt error page
    if (this.errorCode == 404) {
      this.$rollbar.info("Error Page", this.error)
    } else {
      this.$rollbar.warning("Error Page", this.error)
    }
    this.sendHotjarTag(["Error Page"])
  },
}
