
import { mapMutations, mapActions, mapGetters } from "vuex"
import { FadeTransition } from "vue2-transitions"
import Search from "@/dashboard/search/sandwich-search-fuse"
import Sidebar from "@/helpers/navigation/Sidebar"
import ContentFooter from "@/helpers/layouts/ContentFooter"
import NavBar from "@/helpers/navigation/NavBar"
import DarkModeMixin from "@/mixins/dark-mode"
import InsightsSidenavGlobal from "@/dashboard/sidebar/InsightsSidenavGlobal"
import FeatherIcon from "@/helpers/FeatherIcon"
import { generateApiUrl } from "@/utils/helpers"
import axios from "axios"
import _ from "lodash"
import { EventBus } from "@/utils/event-bus"
import PugImg from "@/dashboard/pug-img"
import EmailReportChart from "@/dashboard/cards/email-report-builder/EmailReportChart"
// import draggable from 'vuedraggable'
// ! I chose to use draggable.js vs vuedraggable bc of the Swap functionality. Now you know.
// * Confirming I know now :)

export default {
  name: "adminEmailReport",
  components: {
    Sidebar,
    FadeTransition,
    ContentFooter,
    NavBar,
    Search,
    InsightsSidenavGlobal,
    FeatherIcon,
    PugImg,
    EmailReportChart
  },
  mixins: [DarkModeMixin],
  data() {
    return {
      items: [],
      showSidebar: true,
      showSidebarTitle: true,
      sidebarWidth: '350px',
      sidebarFull: true,
      chartsLoaded: false,
      openAll: false,
      charts: {},
      // charts: { "Other": { active: false, items: [], internal_name: 'Other', key: 0 } },
      chartsOrig: null,
      chartsSearch: [],
      sortable: {},
      searchResults: {},
      isPublishedDisabled: false
    }
  },
  computed: {
    ...mapGetters({
      layoutVars: "layoutVars/getLayout",
    }),
  },
  async created() {
    EventBus.$on('load_charts', (data) => {
      this.loadCharts(data.is_unit_manager)
    })
    this.loadCharts(false)
  },
  beforeDestroy() {
    EventBus.$off("load_charts")
    EventBus.$off("email-report-empty")
  },
  mounted() {
    EventBus.$on("email-report-empty", (isEmpty) => this.isPublishedDisabled = isEmpty)
    this.setSelectedAccount(null)
    this.resetSidebar()
  },
  methods: {
    ...mapActions({
      resetSidebar: "navigation/resetSidebar",
      setSelectedAccount: "accounts/setSelectedAccount",
    }),
    ...mapMutations({
      updateSearchItems: "layoutVars/updateSearchItems",
    }),
    async loadCharts(is_unit_manager) {
      let url = generateApiUrl(`chart-for-builder?type=${ is_unit_manager ? 'unit_manager' : 'sitelink' }`)
      this.charts = {}
      try {
        let { data: res } = await axios.get(url, { headers: { 'x-purge': true } })
        for (let chart of res.data) {
          if (chart.type && chart.type !== "Table" && chart.type !== "Map") {
            if (!this.charts[chart.type]) {
              this.charts[chart.type] = { active: false, items: [], internal_name: chart.type.replace(/\s+/g, '').trim(), key: 0 }
            }
            this.charts[chart.type].items.push(chart)
          }
          else if (!chart.type || (chart.type !== 'Table' && chart.type !== "Map")) {
            chart.type = "Other"
            this.charts["Other"].items.push(chart)
          }
          this.chartsSearch.push(chart)
        }
        this.charts = Object.keys(this.charts).sort().reduce(
          (obj, key) => {
            obj[key] = this.charts[key]
            return obj
          },
          {}
        )
        this.chartsOrig = _.cloneDeep(this.charts)
        this.chartsLoaded = true

      } catch (e) {
        console.log("🚀 ~ file: adminEmailReport.vue ~ line 106 ~ created ~ e", e)
      }
    },
    onClick(type) {
      EventBus.$emit(type)
    },
    setDraggable(id, key) {
      let el = document.getElementById(id)
      this.sortable[key] = new this.$sortable(el, {
        swap: true, // Enable swap mode
        swapClass: "sortable-swap-highlight",
        draggable: ".chart-card",
        sort: false,
        invertSwap: true,
        dataIdAttr: 'data-id',
        group: { name: id, pull: true, put: [id, key == 'Counter' ? 'drop-counter' : 'drop-chart'] },
        onRemove: this.onRemove
      })
    },
    /**
     * * Here we simulate cloning. using sortable.js clone would create duplicates
     */
    onRemove(e) {
      let chart = JSON.parse(e.item.dataset.chart)
      // console.log("🚀 ~ file: adminEmailReport.vue ~ line 192 ~ onRemove ~ e", chart)
      let id = e.from.attributes[0].value
      this.sortable[chart.type].destroy()
      this.charts[chart.type].key++
      this.setDraggable(id, chart.type)
    },
    /**
     * Used when collapsing a group.
     */
    hideDraggable(id, key) {
      this.sortable[key].destroy()
    },
    /**
     * Used when opening up a collapsed group
     */
    toggleGroup(key) {
      this.charts[key].active = !this.charts[key].active
      this.charts = _.cloneDeep(this.charts)
    },
    toggleSidebar() {
      this.sidebarFull = !this.sidebarFull
      if (this.sidebarFull === true) {
        setTimeout(() => {
          this.showSidebarTitle = true
        }, 150)
      }
      else {
        this.showSidebarTitle = false
      }
    },
    handleSelected: _.debounce(function (selected) {
      let keys = _.uniq(_.map(selected, 'id'))
      this.searchResults = {}

      if (selected !== null) {
        this.openAll = true
        this.toggleAllCategories()
        this.searchResults = _.cloneDeep(this.convertArrayToObject(keys))
      } else {
        this.openAll = false
        this.toggleAllCategories(false)
      }
    }, 200),
    toggleAllCategories(isOpen = true) {
      for (let id in this.charts) {
        this.charts[id].active = isOpen
      }
    },
    convertArrayToObject(array,) {
      const initialValue = {}
      for (let item of array) {
        initialValue[item] = true
      }
      return initialValue
    },
    goBack() {
      this.$router.push('/global/email-reports')
    },
  },
}
